<template>
    <v-form v-if="role" class="divide-y-2 divide-default" @submit.prevent="submitRoleForm(role.role_id, roleForm)">
        <v-section-group>
            <v-section-divider>
                General Information
            </v-section-divider>

            <v-card-content>
                <v-form-group>
                    <div class="grid grid-cols-1 lg:grid-cols-4">
                        <div>
                            <v-form-label>Name</v-form-label>
                            <v-form-input v-model="roleForm.name" type="text" :readonly="true" :disabled="isLoading" />
                        </div>
                    </div>
                </v-form-group>
            </v-card-content>
        </v-section-group>

        <v-section-group>
            <v-section-divider>
                Permissions
            </v-section-divider>

            <v-form-group v-if="permissions">
                <!-- <v-form-label>Permissions</v-form-label> -->

                <v-form-group class="grid grid-cols-1 divide-y-2 divide-default">
                    <template v-for="(models, modelIndex) in permissions">
                        <div :key="'model_' + modelIndex">
                            <div v-if="modelIndex.split('-')[0] === 'meta'" class="">
                                <v-section-heading>
                                    Meta Information
                                </v-section-heading>
                            </div>

                            <div class="p-default">
                                <v-form-label>
                                    {{ modelIndex.split('-')[0] === 'meta' ? startCase(camelCase(modelIndex.split('-').slice(1))) : startCase(camelCase(modelIndex)) }}
                                </v-form-label>

                                <v-form-group class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3" :class="modelIndex === 'meta-order-lines' ? 'xl:grid-cols-2' : 'xl:grid-cols-5'">
                                    <template v-for="(permission, permissionIndex) in models">
                                        <v-form-checkbox :key="'permission_' + permissionIndex" v-model="roleForm.permissions" :value="permission.permission_id" class="mr-2">
                                            <span class="block">
                                                {{ parseLabel(startCase(permission.name)) }}
                                            </span>
                                        </v-form-checkbox>
                                    </template>
                                </v-form-group>
                            </div>
                        </div>
                    </template>
                </v-form-group>
            </v-form-group>
        </v-section-group>

        <template v-if="$me.hasPermission('roles.update')">
            <v-form-group class="p-default flex w-full justify-end">
                <v-button class="w-full sm:w-auto" color="primary" :disabled="isLoading">
                    Save Changes
                </v-button>
            </v-form-group>
        </template>
    </v-form>
</template>

<script>
import PermissionService from "@/services/modules/permission-service";
import RoleService from "@/services/modules/role-service";
import Collect from "collect.js";
import { startCase, camelCase } from "lodash";

export default {
    props: {
        role: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            permissionService: PermissionService,
            roleService: RoleService,
            roleForm: {
                name: null,
                permissions: [],
            },
            permissions: {},
        };
    },
    created() {
        this.permissionService.index({ paginated: false })
            .then((response) => {
                this.parsePermissions(response.data);
            });
        this.roleService.getPermissions({ role_id: this.role.role_id })
            .then((response) => {
                this.roleForm.permissions = Collect(response.data.data).pluck("permission_id").toArray();
            });
        this.roleForm.name = this.role.name;
    },
    methods: {
        startCase,
        camelCase,
        parsePermissions(data) {
            const items = Collect(data).sortBy("name").toArray()[0];

            const permissions = {};

            items.data.forEach((item) => {
                const split = item.name.split(".");
                const model = split[0] === "meta" ? `${split[0] }-${ split[1]}` : split[0];
                const action = split.length < 3 || split[0] === "meta" ? split[split.length - 1] : `${split[split.length - 2]} ${split[split.length - 1]}`;

                if (permissions[model] === undefined) {
                    permissions[model] = [];
                }
                permissions[model].push(
                    {
                        permission_id: item.permission_id,
                        name: action,
                    },
                );

                if (split[0] === "meta") {
                    permissions[model] = Collect(permissions[model]).sortBy("name");
                } else {
                    permissions[model] = Collect(permissions[model]).sortBy("permission_id");
                }
            });

            this.permissions = permissions;
        },
        submitRoleForm(roleId, payload) {
            this.toggleLoading();

            this.roleService.update(roleId, payload)
                .then((response) => {
                    this.$emit("role-updated", response);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.toggleLoading();
                });
        },
        createItem(payload = {}) {
            this.roleService.store(payload)
                .catch((error) => {
                    alert(error.response.data.message);
                }).then((response) => {
                    this.$router.push({ name: "roles.show", params: { role: response.data.data.id } });
                });
        },
        parseLabel(label) {
            let smsIndex = label.toLowerCase().indexOf("sms");

            if (smsIndex >= 0) {
                if (smsIndex < 0) {
                    smsIndex = label.length + smsIndex;

                    if (smsIndex < 0) {
                        smsIndex = 0;
                    }
                }

                return `${label.slice(0, smsIndex)}SMS${label.slice(smsIndex + 3)}`;
            }

            return label;
        },
    },
};
</script>
